import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import moment from "moment";
import { database } from "./plugins/firebase";
import JsonViewer from "vue-json-viewer";

Vue.config.productionTip = false;
Vue.use(JsonViewer);

Vue.use(Toast, {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 20,
  newestOnTop: true,
  hideProgressBar: true,
  position: "top-right",
  timeout: 4000,
});

var dbPrefix = "fiona";

Vue.mixin({
  methods: {
    formatDate(str) {
      if (!str) return "";
      return moment(str).format("DD/MM/YYYY HH:mm");
    },
    async $dbWatcher(ref, func) {
      database.ref(`${dbPrefix}/${ref}`).on("value", (snapshot) => {
        var val = snapshot.val();
        if (!func) return;
        func(val || null);
      });
    },
    async $dbSet(ref, data) {
      return new Promise((resolve) => {
        return database.ref(`${dbPrefix}/${ref}`).set(data, (err) => {
          if (err) {
            alert(err.message);
            return resolve(false);
          }
          return resolve(true);
        });
      });
    },
    async $dbRemove(ref) {
      return new Promise((resolve) => {
        database
          .ref(`${dbPrefix}/${ref}`)
          .remove()
          .then(() => {
            return resolve(true);
          });
      });
    },
    async $dbGet(ref) {
      return new Promise((resolve) => {
        database
          .ref()
          .child(`${dbPrefix}`)
          .child(ref)
          .get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              return resolve(snapshot.val());
            } else {
              console.log("No data available");
              return resolve(false);
            }
          })
          .catch((error) => {
            console.error(error);
            return resolve(false);
          });
      });
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
