import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "flow",
    component: () => import("@/layouts/BlankLayout"),
    children: [
      {
        path: "home",
        component: HomeView,
      },
      {
        path: "flow",
        component: () => import("@/layouts/HomeLayout"),
        redirect: "/list",
        children: [
          {
            path: "/list",
            component: () => import(`@/components/FlowEditor/FlowList.vue`),
          },
          {
            path: "/editor",
            component: () => import(`@/components/FlowEditor/FlowDesigner.vue`),
          },

          {
            path: "/task",
            component: () => import("@/components/FlowEditor/TaskList.vue"),
          },
        ],
      },
      {
        path: "/inspector",
        component: () => import(`@/components/FlowEditor/FlowInspector.vue`),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  },
  routes,
});

export default router;
