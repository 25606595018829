import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyC9OUgxdx89IIBBkLLjYuW7tCLJv_l5V4Y",
  authDomain: "sandrasoft-8fe2b.firebaseapp.com",
  projectId: "sandrasoft-8fe2b",
  storageBucket: "sandrasoft-8fe2b.appspot.com",
  messagingSenderId: "247597878491",
  appId: "1:247597878491:web:58451d1659da966208d0fe",
  measurementId: "G-26M2K5J74E",
  databaseURL:
    "https://sandrasoft-8fe2b-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const database = firebase.database();
